<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <h4>{{ $t('backoffice.settings.subtitle-links') }}</h4>
      <div class="d-flex">
        <b-button 
          variant="primary" 
          class="ml-1" 
          @click="saveLinks()"
        >
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </div>

    <!-- Place publish -->
    <b-row v-if="canHaveExternalLinks">
      <b-col cols="12">
        <b-form-group>
          <label class="font-weight-bold" for="place">{{ $t('events.actions.place-publish') }}</label>
          <v-select
            id="place"
            v-model="placePublishOption"
            :clearable="false"
            :reduce="(place) => place.code"
            :options="placePublishOptions"
            label="place"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="placePublishOption===1" cols="12">
        <b-form-group :label="$t('events.actions.link')" label-for="register-link">
          <b-form-input
            id="register-link"
            v-model="registrationURL"
            type="url"
            autofocus
            trim
            :placeholder="$t('events.actions.link-placeholder')"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="placePublishOption===2" cols="12">
        <b-form-group :label="$t('events.actions.link')" label-for="join-link">
          <b-form-input
            id="join-link"
            v-model="joinURL"
            type="url"
            autofocus
            trim
            :placeholder="$t('events.actions.link-placeholder')"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: 'EventLink',
  components: {
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      joinURL: null,
      registrationURL: null,
      placePublishOption: 0,
      placePublishOptions: [
        { code: 0, place: this.$t('events.event-details.here-place') },
        { code: 1, place: this.$t('events.event-details.external-place') },
        { code: 2, place: this.$t('events.event-details.hybrid-place') },
      ],
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    canHaveExternalLinks() {
      return ['Event', 'Project', 'Challenge', 'Course'].includes(this.currentCollective.mainType);
    },
  },
  watch: {
    //
  },
  created() {
    this.joinURL = this.currentCollective.joinURL ?? null;
    this.registrationURL = this.currentCollective.registrationURL ?? null;
    this.placePublish();
  },
  methods: {
    placePublish() {
      if (this.currentCollective.joinURL) {
        this.placePublishOption = 2;
      }
      if (this.currentCollective.registrationURL) {
        this.placePublishOption = 1;
      }
    },
    async saveLinks() {
      console.log('Saving links...')
      if (this.canHaveExternalLinks) {
        if (this.placePublishOption === 0) {
          this.$emit('internal');
        } else if (this.placePublishOption === 2) {
          this.$emit('joinURL', this.joinURL);
        } else if (this.placePublishOption === 1) {
          this.$emit('registrationURL', this.registrationURL);
        }
      }
    },
  },
};
</script>
